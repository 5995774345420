<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>提现列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="公司名称">
                <el-input v-model="search.company_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提现时间">
                <el-date-picker
                  v-model="search.add_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input v-model="search.order_no"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单状态">
                <el-select
                  v-model="search.settle_state"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option label="全部" value="all"></el-option>
                  <el-option label="结算中" :value="1"></el-option>
                  <el-option label="结算完成" :value="2"></el-option>
                  <el-option label="拒绝" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提现金额">
                <el-input v-model="search.withdrawal_money"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button
                  icon="el-icon-circle-close"
                  size="medium"
                  @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
      <!--列表-->
      <el-table v-loading="loading" :data="tableData" border size="medium">
        <el-table-column prop="company_name" label="公司名称">
        </el-table-column>
        <el-table-column width="180" prop="withdraw_no" label="订单号">
        </el-table-column>
        <el-table-column width="180" prop="withdraw_time" label="提现时间">
        </el-table-column>
        <el-table-column width="180" prop="state_time" label="审批时间">
          <template slot-scope="scope">
            <span v-if="scope.row.state_time != '1970-01-01 08:00:00'">{{
              scope.row.state_time
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" prop="settle_state" label="提现状态">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.state === 1" type="success"
              >同意提现</el-tag
            >
            <el-tag size="mini" v-if="scope.row.state === 2" type="danger"
              >拒绝提现</el-tag
            >
            <el-tag size="mini" v-if="scope.row.state === 0" type="success"
              >提现中</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="withdraw_amount" label="提现金额">
        </el-table-column>
        <el-table-column
          width="180"
          prop="withdraw_before_amount"
          label="提现前可用"
        >
        </el-table-column>
        <el-table-column
          width="180"
          prop="withdraw_after_amount"
          label="提现后剩余"
        >
        </el-table-column>
        <el-table-column width="180" prop="remark" label="备注">
        </el-table-column>
        <el-table-column prop="state_remark" label="审批备注">
        </el-table-column>
        <el-table-column width="180" prop="bank_card_no" label="银行卡号">
        </el-table-column>
        <el-table-column prop="bank_card_name" label="账户名">
        </el-table-column>
        <el-table-column prop="bank_of_deposit" label="开户行">
        </el-table-column>
        <el-table-column prop="operator_name" label="审批人姓名">
        </el-table-column>
        <el-table-column prop="company_contacts" label="申请人姓名">
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.state === 0"
              @click="batch(scope.row)"
              size="mini"
              >审批
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px;"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
      <!--审批-->
      <el-dialog
        title="审批"
        :visible.sync="settagPage"
        width="50%"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-row>
          <el-form size="medium" ref="form" label-width="120px">
            <el-col :span="24">
              <el-form-item label="审批备注">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  :maxlength="300"
                  v-model="settle_remarks"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px;">
          <el-button
            v-if="is_auth('accountbook.withdrawal.auditpass')"
            @click="isoperation(2)"
            size="medium"
            type="success"
            >通过
          </el-button>
          <el-button
            v-if="is_auth('accountbook.withdrawal.auditreject')"
            @click="isoperation(0)"
            size="medium"
            type="danger"
            >不通过
          </el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page_name: "提现列表",
      issearch: true,
      loading: true,
      search: {}, // 搜索条件
      tableData: [], // 列表内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      searchtime: null,
      settagPage: false,
      settle_remarks: "",
      info: {},
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() {},
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    //初始化
    init() {
      this.search = {
        company_name: "",
        order_no: "",
        add_date: "",
        settle_state: "all",
        withdrawal_money: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "accountbook.withdrawal.getnewlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.$message.error(json.message);
        }
      });
    },
    // 弹窗显示
    batch(info) {
      this.settagPage = true;
      this.info = info;
    },
    // 操作提示
    operation_tip(settle_state = 0) {
      let tip = "";
      let options = {};
      // 通过
      if (settle_state === 2) {
        tip = "审批通过？";
      }
      // 不通过
      if (settle_state === 0) {
        tip = "审批不通过？";
      }

      // 弹出二次确认
      this.$confirm(tip, "确认信息", options)
        .then(() => {
          this.isoperation(settle_state);
        })
        .catch(() => {});
    },
    // 操作
    isoperation(settle_state) {
      let postdata = {
        api_name: settle_state === 2 ?  "accountbook.withdrawal.auditpass" : "accountbook.withdrawal.auditreject",
        token: this.Tool.get_l_cache("token"),
        withdraw_uuid: this.info.withdraw_uuid,
        remark: this.settle_remarks,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        this.settagPage = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
          this.settle_remarks = "";
        } else {
          this.Tool.errormes(json);
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
